footer{
    position: fixed;
    left:0;
    right:0;
    bottom: 0;
    padding: 10px;
    color: white;
    background-color: #35424a;
    text-align: center;
    border-top: orangered 3px solid;
}

 html {
    height: 100%;
    box-sizing: border-box; 
  }

    /* *,
    *:before,
    *:after {
    box-sizing: inherit;
    } */

  body {
    position: relative;
    min-height: 100%;
    margin: 1px0;
    padding-bottom: 6rem;
  }