


.sidenav {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: #2e3235;
    overflow-x: hidden;
}



.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    display: block;
}

.sidenav a:hover {
    color: #f1f1f1;
    text-decoration: none;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 5px;
    font-size:30px;
    padding:0;
    margin-right: 5px;
}



/* @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
} */

.side-header{
    margin: 0;
    color:white;
    height: 60px;
    border-bottom: orangered 2px solid;
}
.side-header .brand-name{
    padding-top: 8px;
    font-size: 24px;
    font-weight: bold;
    color:gainsboro;
}
.side-header .brand-img{
    /* float: left; */
    height: 35px;
    width:145px;
    margin-top: 5px;
}
.side-header .brand-name i{
    font-size: 36px;
    padding-right: 5px;
    color: gainsboro;
}

.side-header .second-title{
    color: gainsboro;
    /* font-weight: bold; */
    font-size:18px;
    margin-top: -8px;
    padding-left: 16px;
}


.side-link .current{
    color:white;
}
.sidenav .logout{
    display: none;
}

@media(max-width:768px){
    .sidenav{
        /* position: static; */
        transition: width 100ms;
        width: 0px;
    }
    .sidenave-show{
        transition: width 100ms;
        width: 200px;
    }
    .main{
        margin-left:0;
    }
    .sidenav .logout{
        display: block;
    }
}


